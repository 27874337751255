import '../viewer/viewer.css';
import { useState, useCallback, useRef, useEffect } from 'preact/hooks';
import { EMBEDDED_DRAWIO_EDITOR_URL } from '../../url-constants';
import useParentSystemConfiguration from '../../hooks/useParentSystemConfiguration';
import useDrawing from '../../hooks/useDrawing';
import { postDrawing, putDrawing } from '../../util/draw-api';
import useParentSystemEvents from '../../hooks/useParentSystemEvents';
import useDrawIoPluginsApi from '../../hooks/useDrawIoPluginsApi';
import useDrawIoEvents from '../../hooks/useDrawIoEvents';
import useDrawIoApi from '../../hooks/useDrawIoApi';
import useParentSystemApi from '../../hooks/useParentSystemApi';
import useDrawIoPluginEvents from '../../hooks/useDrawIoPluginEvents';
import LoadingScreen from '../common/LoadingScreen';
import useDrawIoPlugins from '../../hooks/useDrawIoPlugins';
import {
  DRAWIO_DEFAULT_CONFIGURATION,
  DRAWIO_MENU_CONFIGURATION,
} from './editor-utils';

function Wrapper({ uuid, source, lang }) {
  const parentSystemConfiguration = useParentSystemConfiguration();
  const drawingId = uuid || source;
  const xml = useDrawing(drawingId, parentSystemConfiguration);

  return parentSystemConfiguration && (xml || !drawingId) ? (
    <Editor
      parentSystemConfiguration={parentSystemConfiguration}
      xml={xml}
      uuid={uuid}
      lang={lang}
    />
  ) : null;
}

function Editor({ parentSystemConfiguration, xml, uuid, lang }) {
  const drawIoRef = useRef(null);
  const [drawIoLoaded, setDrawIoLoaded] = useState(false);
  const [id, setId] = useState(uuid);
  const [, setSaving] = useState(false);
  const [failedSaveRequest, setFailedSaveRequest] = useState(null);
  useDrawIoPlugins([
    '/src/plugins/uniqueid.js',
    '/src/plugins/export.js',
    '/src/plugins/editor-menu.js',
    ...(parentSystemConfiguration?.deleteConfirmation
      ? ['/src/plugins/delete-confirmation.js']
      : []),
  ]);

  const {
    configure: configureDrawIo,
    load: loadDrawIo,
    exportPng,
  } = useDrawIoApi(drawIoRef);
  const {
    setSavingStateSuccess,
    setSavingStateFailed: setDrawIoSavingStateFailed,
    configureDeleteConfirmation,
    initMenus,
    exportWithObjectData,
  } = useDrawIoPluginsApi(drawIoRef);
  const {
    triggerExit,
    triggerJwtUpdate,
    triggerSave: triggerParentSystemSave,
    triggerUpdate: triggerParentSystemUpdate,
  } = useParentSystemApi();

  const handleConfigure = useCallback(
    () =>
      configureDrawIo({
        ...DRAWIO_DEFAULT_CONFIGURATION,
        ...parentSystemConfiguration?.drawioConfiguration,
      }),
    [configureDrawIo, parentSystemConfiguration?.drawioConfiguration],
  );

  const handleDrawIoInit = useCallback(() => {
    loadDrawIo(xml);
  }, [loadDrawIo, xml]);

  const handleLoad = useCallback(() => {
    if (parentSystemConfiguration?.deleteConfirmation) {
      const { objectIds, message } =
        parentSystemConfiguration.deleteConfirmation;
      configureDeleteConfirmation(objectIds, message);
    }
    initMenus(DRAWIO_MENU_CONFIGURATION);
    setDrawIoLoaded(true);
  }, [parentSystemConfiguration, configureDeleteConfirmation, initMenus]);

  const handleExit = useCallback(() => {
    triggerExit();
  }, [triggerExit]);

  const handleExport = useCallback(
    (msg) => {
      exportWithObjectData(msg);
    },
    [exportWithObjectData],
  );

  const saveNewDrawing = useCallback(
    (data, authentication) => {
      postDrawing(data, authentication)
        .then((response) => {
          setId(response.data.uuid);
          triggerParentSystemSave(
            response.data.uuid,
            data.image,
            data.objectData,
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            triggerJwtUpdate();
            setFailedSaveRequest({
              data,
              authentication,
            });
          } else {
            setDrawIoSavingStateFailed();
          }

          console.error(error.message);
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [setDrawIoSavingStateFailed, triggerJwtUpdate, triggerParentSystemSave],
  );

  const updateDrawing = useCallback(
    (data, authentication) => {
      putDrawing(id, data, authentication)
        .then(() => {
          triggerParentSystemUpdate(id, data.image, data.objectData);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            triggerJwtUpdate();
            setFailedSaveRequest({
              data,
              authentication,
            });
          } else {
            setDrawIoSavingStateFailed();
          }
          console.error(error.message);
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [
      id,
      setDrawIoSavingStateFailed,
      triggerJwtUpdate,
      triggerParentSystemUpdate,
    ],
  );

  const saveDrawing = useCallback(
    (data, authentication) => {
      if (id) {
        updateDrawing(data, authentication);
      } else {
        saveNewDrawing(data, authentication);
      }
    },
    [id, updateDrawing, saveNewDrawing],
  );

  const handleSave = useCallback(
    (data) =>
      setSaving((prevState) => {
        const noPendingSaveRequests = !prevState;
        if (noPendingSaveRequests) {
          saveDrawing(data, parentSystemConfiguration.authentication);
        }
        return true;
      }),
    [saveDrawing, parentSystemConfiguration],
  );

  const handleExportWithObjectData = useCallback(
    (evt) => handleSave(evt.data.data),
    [handleSave],
  );

  const readyToListenParentSystemEvents = useParentSystemEvents({
    onSavingSuccess: setSavingStateSuccess,
    onSavingFailed: setDrawIoSavingStateFailed,
  });
  const readyToListenDrawIoEvents = useDrawIoEvents({
    onConfigure: handleConfigure,
    onInit: handleDrawIoInit,
    onSave: exportPng,
    onExport: handleExport,
    onLoad: handleLoad,
    onExit: handleExit,
  });
  const readyToListenDrawIoPluginEvents = useDrawIoPluginEvents({
    onExportEvent: handleExportWithObjectData,
  });

  useEffect(() => {
    if (
      failedSaveRequest &&
      failedSaveRequest.authentication.jwt !==
        parentSystemConfiguration.authentication.jwt
    ) {
      saveDrawing(
        failedSaveRequest.data,
        parentSystemConfiguration.authentication,
      );
      setFailedSaveRequest(null);
    }
  }, [failedSaveRequest, parentSystemConfiguration, saveDrawing]);

  return (
    <div className="drawio">
      {readyToListenParentSystemEvents &&
      readyToListenDrawIoEvents &&
      readyToListenDrawIoPluginEvents ? (
        <iframe
          ref={drawIoRef}
          src={`${EMBEDDED_DRAWIO_EDITOR_URL}&lang=${lang}`}
          title="drawio"
          data-testid="drawio"
        />
      ) : null}
      {drawIoLoaded ? null : (
        <LoadingScreen
          language={lang}
          spinnerColor={parentSystemConfiguration.colors?.spinner}
        />
      )}
    </div>
  );
}

export default Wrapper;
