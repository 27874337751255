import { useEffect, useCallback, useState } from 'preact/hooks';
import {
  CONFIGURE,
  REFRESH_JWT,
  sendConfigureMessage,
} from '../util/parent-system-message-api';

function useParentSystemConfiguration() {
  const [readyToListen, setReadyToListen] = useState(false);
  const [configuration, setConfiguration] = useState(null);

  const receive = (evt) => {
    if (evt.data.message && evt.data.message === CONFIGURE) {
      setConfiguration(evt.data.configuration);
    }
    if (evt.data.message && evt.data.message === REFRESH_JWT) {
      setConfiguration((prevConfiguration) => ({
        ...prevConfiguration,
        authentication: { jwt: evt.data.jwt },
      }));
    }
  };

  useEffect(() => {
    if (readyToListen) {
      sendConfigureMessage();
    }
  }, [readyToListen]);

  useEffect(() => {
    window.addEventListener('message', receive);
    setReadyToListen(true);
    return () => {
      window.removeEventListener('message', receive);
    };
  }, []);

  return configuration;
}

export default useParentSystemConfiguration;
